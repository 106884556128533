import React, { useEffect, useState } from 'react';

import { useSelector } from 'react-redux';

import UserService from '../../../../../services/UserService';
import { PlusAdvantageShopTab } from '../PlusAdvantageShopTab/PlusAdvantageShopTab';
import { PlusSelectSubscription } from '../PlusSelectSubscription/PlusSelectSubscription';


export const PlusAdvantageTabPanel = () => {
  const user = useSelector(({ user }) => user);
  const [isSubscriber, setIsSubscriber] = useState(UserService.isUserSubscriber());

  console.log('user', user);
  useEffect(() => {
    setIsSubscriber(UserService.isUserSubscriber());
  }, [user]);
  return <>{isSubscriber ? <PlusAdvantageShopTab /> : <PlusSelectSubscription />}</>;
};
